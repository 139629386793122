import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Grid, Modal, withStyles, styled } from "@material-ui/core";
import Done from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import css from "./pricing.module.css";
import Contact from "../components/contact";
import Customers from "../components/customers";
import Faq from "../components/faq";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Spacer from "../components/spacer";

const DoneIcon = withStyles({
  root: {
    color: "#717BAC",
    fontSize: 16,
    verticalAlign: "middle",
  },
})(Done);

const Title = styled("div")(() => ({
  color: "#66788c",
  fontSize: 12,
  textAlign: "left",
  marginBottom: 20,
  padding: "0 16px",
}));

const TabsWrapper = styled("div")(() => ({
  overflow: "auto",
  width: "100%",
  paddingBottom: 8,
  MsOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */,
  "&::-webkit-scrollbar": {
    /* Chrome, Safari and Opera */ display: "none",
  },
}));

const Tabs = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up("md")]: {
    display: "grid",
  },
}));

const Tab = styled("div")(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : "#66788c",
  borderWidth: "0 0 2px 0",
  borderStyle: "solid",
  borderColor: active ? theme.palette.primary.main : "transparent",
  fontWeight: 500,
  fontSize: 14,
  padding: "12px 14px",
  whiteSpace: "nowrap",
  cursor: "pointer",
  [theme.breakpoints.up("md")]: {
    borderWidth: "0 0 0 2px",
  },
}));

const nonStarterModels = ["Menu Cards"];

const NewOcrCards = ({ data, activeModelIndex }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openModal = () => {
    setIsDialogOpen(true);
  };

  const closeModal = () => {
    setIsDialogOpen(false);
  };

  const pageData = data.markdownRemark.frontmatter.data;
  const models = pageData.models;
  const model = models[activeModelIndex];

  return (
    <div>
      <Grid container spacing={2} justify="center">
        {nonStarterModels.includes(model.model_name) ? null : (
          <Grid item xs={12} sm={6} md={4}>
            <div className={css.plan_card}>
              <div className={css.plan_card_top}>
                <span className={css.plan_name}>Starter</span>
                <span className={css.plan_heading2}>
                  For individuals or those looking to try out the platform
                </span>
                <div className={css.card_sub}>
                  <span style={{ borderBottom: "1px dashed #546fff" }}>
                    {model.model_name}
                  </span>
                </div>
                <a
                  href="https://app.nanonets.com/"
                  className={css.button}
                  style={{ background: "white", color: "#546fff" }}
                >
                  Sign Up For Free
                </a>
              </div>

              <div className={css.price} style={{ height: 40 }}>
                <span className={css.amount}>$0</span>
                <span className={css.sub}>/model/month</span>
              </div>

              <div className={css.features}>
                <ul>
                  {model.summary[0].rows.map((row, index) => (
                    <li key={index}>
                      <DoneIcon />
                      <span className={css.feature_name}>{row.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <div className={css.plan_card}>
            <div className={css.plan_card_top}>
              <span className={css.plan_name}>Pro</span>
              <span className={css.plan_heading2}>
                For teams seeking advanced tools and workflow setups for their
                growing business
              </span>
              <div className={css.card_sub}>
                <span style={{ borderBottom: "1px dashed #546fff" }}>
                  {model.model_name}
                </span>
              </div>
              <a
                href="https://app.nanonets.com/#/models"
                className={css.button}
              >
                free trial for 7 days
              </a>
            </div>

            <div className={css.price} style={{ height: 40 }}>
              <span className={css.amount}>$499</span>
              <span className={css.sub}>/model/month</span>
            </div>

            <div className={css.features}>
              <ul>
                {model.summary[1].rows.map((row, index) => (
                  <li key={index}>
                    <DoneIcon />
                    <span className={css.feature_name}>{row.text}</span>
                  </li>
                ))}
              </ul>

              {model.model_name == "Custom Model" && (
                <div
                  style={{
                    fontSize: 12,
                    marginTop: 30,
                    paddingTop: 12,
                    borderTop: "1px solid #E3E8EE",
                  }}
                >
                  <p style={{ margin: "0 0 8px", color: "#66788C" }}>
                    * Extra fields and retrains (Free for Trial)
                  </p>
                  <p style={{ margin: "0 0 8px" }}>
                    <span style={{ color: "#354454" }}>$0.01</span>{" "}
                    <span style={{ color: "#94A1AF" }}>/ field / page</span>
                    <span>
                      <InfoIcon
                        style={{
                          fontSize: 14,
                          color: "#C1C8D0",
                          cursor: "pointer",
                          marginLeft: 6,
                          verticalAlign: "middle",
                        }}
                        onClick={openModal}
                      />
                    </span>
                  </p>
                  <p style={{ margin: "0 0 8px" }}>
                    <span style={{ color: "#354454" }}>$50</span>{" "}
                    <span style={{ color: "#94A1AF" }}>
                      / additional retrain
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <div className={css.plan_card}>
            <div className={css.plan_card_top}>
              <span className={css.plan_name}>Enterprise</span>
              <span className={css.plan_heading2}>
                For organizations that need additional security and control to
                process data at scale
              </span>
              <div className={css.card_sub}>
                <span style={{ borderBottom: "1px dashed #546fff" }}>
                  {model.model_name}
                </span>
              </div>
              <a href="#contact" className={css.button}>
                Contact Sales
              </a>
            </div>

            <div
              style={{
                fontSize: 12,
                color: "#66788c",
                textAlign: "center",
                height: 40,
              }}
            >
              <span>
                Talk to us to get an estimate and specify custom requirements
                for your business.
              </span>
            </div>

            <div className={css.features}>
              <ul>
                {model.summary[2].rows.map((row, index) => (
                  <li key={index}>
                    <DoneIcon />
                    <span className={css.feature_name}>{row.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal
        style={{ display: "flex" }}
        open={isDialogOpen}
        onClose={closeModal}
      >
        <div
          style={{
            maxWidth: 480,
            margin: "auto",
            padding: 40,
            borderRadius: 4,
            background: "#fff",
            overflow: "auto",
            outline: 0,
            position: "relative",
          }}
        >
          <div>
            <p style={{ color: "#354454", fontSize: 16, fontWeight: 600 }}>
              How number of pages is calculated?
            </p>
            <p style={{ color: "#94A1AF", fontSize: 14, fontWeight: 600 }}>
              Included Pages
            </p>
            <p style={{ color: "#354454", fontSize: 12 }}>
              Number of Pages is calculated based on the number of fields in the
              model.
            </p>
            <p style={{ color: "#354454", fontSize: 12 }}>
              If there are 5 fields, The number of pages is 50,000/5 = 10,000.
              <br />
              If there are 10 fields, The number of pages is 50,000/10 = 5,000.
            </p>
            <p style={{ color: "#94A1AF", fontSize: 14, fontWeight: 600 }}>
              Cost of Additional Pages
            </p>
            <p style={{ color: "#354454", fontSize: 12 }}>
              The Total Number of Pages multiplied by the Number of Fields in
              the model is set to a 50,000. After that charges apply at
              $0.01/field/page. For 10 fields the cost is $0.1/Additional Page,
              for 5 fields it would be $0.05/Additional Page
            </p>
          </div>

          <div className={css.closeIcon} onClick={closeModal}>
            <CloseIcon color="inherit" fontSize="inherit" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Pricing = ({ data }) => {
  const [activeModelIndex, setActiveModelIndex] = useState(0);

  // const tab_index = 0;
  const pageData = data.markdownRemark.frontmatter.data;
  // const product = pageData.products[tab_index];
  const models = pageData.models;
  const model = models[activeModelIndex];
  // const custom_model_features = product.features[0].custom_model;
  // const pretrained_model_features = product.features[1].pretrained_model;
  let features = model.features;

  return (
    <Layout>
      <SEO title={pageData.seoTitle} description={pageData.seoDescription} />

      <section style={{ background: "#E9ECFF", paddingTop: 58 }}>
        <div>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                color: "#3C4469",
                fontSize: 20,
                fontWeight: 500,
                margin: 0,
                textAlign: "center",
              }}
            >
              Simple &amp; Transparent Pricing
            </p>
            <Spacer h={50} />
          </div>
        </div>
      </section>

      <Container>
        <div
          style={{
            margin: -16,
            display: "flex",
            flexWrap: "wrap",
            paddingTop: 50,
            paddingBottom: 100,
          }}
        >
          <div
            style={{
              padding: 16,
              flex: "1 1 120px",
              width: "100%",
            }}
          >
            <Title>SELECT A MODEL</Title>

            <TabsWrapper>
              <Tabs>
                {models.map((model, index) => (
                  <Tab
                    key={index}
                    active={index === activeModelIndex}
                    onClick={() => {
                      setActiveModelIndex(index);
                    }}
                  >
                    {model.model_name}
                  </Tab>
                ))}
              </Tabs>
            </TabsWrapper>
          </div>

          <div style={{ padding: 16, flex: "2 2 800px" }}>
            <NewOcrCards data={data} activeModelIndex={activeModelIndex} />
          </div>
        </div>
      </Container>

      <Container>
        <div style={{ margin: "auto" }}>
          <p style={{ fontSize: 14, margin: 0, padding: "30px 0" }}>
            <span style={{ color: "#94A1AF" }}>Compare all plans for </span>
            <span style={{ fontWeight: 600 }}>
              OCR
              {model.model_name && `: ${model.model_name}`}
            </span>
          </p>

          <div style={{ overflow: "auto", maxWidth: "100%" }}>
            <table className={css.table}>
              <tbody>
                <tr>
                  <td style={{ color: "#66788c" }}>Plans</td>
                  <td>Starter</td>
                  <td>Pro</td>
                  <td>Enterprise</td>
                </tr>
                {features.map((feature) => (
                  <tr key={feature.name}>
                    <td style={{ color: "#66788c", fontSize: 12 }}>
                      {feature.name}
                    </td>
                    <td>{feature.starter}</td>
                    <td>{feature.pro}</td>
                    <td>{feature.enterprise}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>

      <Spacer h={100} />

      <Container>
        <Faq faq={pageData.faq} />
      </Container>

      <div style={{ marginBottom: 160 }}></div>

      <Container>
        <Customers />
      </Container>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "pricing" } }) {
      frontmatter {
        data {
          seoTitle
          seoDescription
          page_title
          models {
            model_name
            summary {
              plan_name
              rows {
                text
              }
            }
            features {
              name
              starter
              pro
              enterprise
            }
          }
          faq {
            question
            answer
          }
        }
      }
    }
  }
`;
